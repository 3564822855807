import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

// export const UNI_STABLE_VAULT: AddressMap = constructSameAddressMap('0xA21B1886C354075c50a2F2f818c967F5C2caD950')
export const UNI_STABLE_VAULT: AddressMap = {
  [SupportedChainId.MAINNET]: '0xA21B1886C354075c50a2F2f818c967F5C2caD950',
  [SupportedChainId.BASE]: '0x3bdfe67009FEbf4bc65378F1C07E7FfEF5339407',
  [SupportedChainId.OPTIMISM]: '0xFb35c8715D4c0eACB52C18A7FE614d099F760AD2',
  [SupportedChainId.SCROLL]: '0x3bdfe67009FEbf4bc65378F1C07E7FfEF5339407',
  [SupportedChainId.SONIC]: '0xe924E501581798BA74E23ec1Cf39901aA4301fF0',
}
export const UNI_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'),
  [SupportedChainId.BASE]: '0x4493faE71502871245B7049580b3195bC930e661',
  [SupportedChainId.SCROLL]: '0x3cfe56cacf4042057645da9472f6cd51fcb05684',
  [SupportedChainId.SONIC]: '0xf5CFC98Ad70c924D17C9fe34D12E388F9492a806',
}
export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BASE]: '0xe5963df55148be480d9c9786468ebe9dbac64983',
  [SupportedChainId.OPTIMISM]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.SCROLL_TESTNET]: '0x234BdB5EeCa0384a3530C5c9af35d9E95B4ecA59',
  [SupportedChainId.SCROLL]: '0xC1D2e074C38FdD5CA965000668420C80316F0915',
  [SupportedChainId.SONIC]: '0xF64bBE936974A130FcAA1f746132F94B089C1dF5',
}

export const CONJURER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x27f9ec56F91e9851443417D307E538e919E47219',
  [SupportedChainId.BASE]: '0xA8Eea1eAe8f7483F7aD0555783A18F730Da1AffD',
  [SupportedChainId.OPTIMISM]: '0x75bCF285B1B1DAA01fB1eD52C2787628e2eBa7C1',
  [SupportedChainId.SCROLL]: '0x9F24de635C78C5Df77F9EA9aA3C6D71FfcabEc8f',
  [SupportedChainId.SONIC]: '0xaCC805b918C7FBDcf458d96A02f52015eeA3C790',
}
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)

export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
export const V3_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564'),
  [SupportedChainId.SCROLL_TESTNET]: '0x6E4F8949eb1C0311a5c03D8665c19616158CDfc8',
  [SupportedChainId.SCROLL]: '0xF9Fda3EEcb1398E7C084A0A93Ceb90F1D5937d91',
  [SupportedChainId.SONIC]: '0xA21B1886C354075c50a2F2f818c967F5C2caD950',
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45'),
  [SupportedChainId.BASE]: '0x2626664c2603336E57B271c5C0b26F421741e481',
  [SupportedChainId.OPTIMISM]: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
  [SupportedChainId.SCROLL_TESTNET]: '0x6E4F8949eb1C0311a5c03D8665c19616158CDfc8',
  [SupportedChainId.SCROLL]: '0xF9Fda3EEcb1398E7C084A0A93Ceb90F1D5937d91',
  [SupportedChainId.SONIC]: '0xA21B1886C354075c50a2F2f818c967F5C2caD950',
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS),
  [SupportedChainId.OPTIMISM]: V3_FACTORY_ADDRESS,
  [SupportedChainId.BASE]: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
  [SupportedChainId.SCROLL_TESTNET]: '0x2064D421C0962FB37B2E61d90F01087B6c575F0c',
  [SupportedChainId.SCROLL]: '0x0DF45d6e3BC41fd8e50d9e227215413053c003Ad',
  [SupportedChainId.SONIC]: '0x75bCF285B1B1DAA01fB1eD52C2787628e2eBa7C1',
}
export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'),
  [SupportedChainId.OPTIMISM]: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
  [SupportedChainId.BASE]: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a',
  [SupportedChainId.SCROLL_TESTNET]: '0x7c1D7d3E0D2C2Ef9b33e2890f1c3ff0eA80Ef7b3',
  [SupportedChainId.SCROLL]: '0xcf466b70F06c805617451b86C932bACF7A4892c7',
  [SupportedChainId.SONIC]: '0x956ffD75931dCcf46C31050f9bA3479Cb498444F',
}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedChainId.BASE]: '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1',
  [SupportedChainId.OPTIMISM]: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedChainId.SCROLL_TESTNET]: '0x20582BFdC3a4a15703Ae1c73db5dC7aE47Dd2585',
  [SupportedChainId.SCROLL]: '0x526332278D7774481fA61b49289F2F1581d0BD50',
  [SupportedChainId.SONIC]: '0x8e3E9E3aCa55951AB95E39402150724E6B9d0e39',
}
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34'),
  [SupportedChainId.BASE]: '0x23cF10b1ee3AdfCA73B0eF17C07F7577e7ACd2d7',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.SCROLL_TESTNET]: '0xA222b0ba625A686A1cE1DB01fF6e79dEF87C2351',
  [SupportedChainId.SCROLL]: '0x85780e12e90D2a684eB8E7404c985b5B5c8ce7E9',
  [SupportedChainId.SONIC]: '0x7692e203564b062b14116fc2357F17364074B686',
}
