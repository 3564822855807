import { Token } from '@uniswap/sdk-core'

import { SupportedChainId } from './chains'
import {
  DAI,
  DAI_BASE,
  DAI_OPTIMISM,
  FRAX,
  SUSD_OPTIMISM,
  USDBC_BASE,
  USDC_BASE,
  USDC_E_OP,
  USDC_MAINNET,
  USDC_OPTIMISM,
  USDC_SCROLL,
  USDC_SONIC,
  USDT,
  USDT_SCROLL,
  WBTC,
  WBTC_OPTIMISM,
  WETH_BASE,
  WETH_MAINNET,
  WETH_OP,
  WETH_SCROLL,
  WETH_SONIC,
  WSonic,
  WSteth_BASE,
  WSteth_MAINNET,
  WSteth_OP,
  WSteth_SCROLL,
} from './tokens'

interface WhiteListTokens {
  [key: number]: (Token | undefined)[]
}
interface WhiteListTokenAddresses {
  [key: number]: (string | undefined)[]
}
export const whitelistTokens: WhiteListTokens = {
  [SupportedChainId.MAINNET]: [WBTC, USDC_MAINNET, USDT, DAI, FRAX, WETH_MAINNET, WSteth_MAINNET],
  [SupportedChainId.BASE]: [WETH_BASE, WSteth_BASE, USDC_BASE, USDBC_BASE, DAI_BASE],
  [SupportedChainId.OPTIMISM]: [
    WETH_OP,
    WSteth_OP,
    WBTC_OPTIMISM,
    USDC_OPTIMISM,
    USDC_E_OP,
    DAI_OPTIMISM,
    SUSD_OPTIMISM,
  ],
  [SupportedChainId.SCROLL]: [WETH_SCROLL, USDT_SCROLL, USDC_SCROLL, WSteth_SCROLL],
  [SupportedChainId.SONIC]: [WSonic, USDC_SONIC, USDT_SCROLL, WETH_SONIC],
}
export const whiteListTokenAddresses: WhiteListTokenAddresses = {
  [SupportedChainId.MAINNET]: whitelistTokens[1].map((token) => token?.address),
  [SupportedChainId.BASE]: whitelistTokens[8453].map((token) => token?.address),
  [SupportedChainId.OPTIMISM]: whitelistTokens[10].map((token) => token?.address),
  [SupportedChainId.SCROLL]: whitelistTokens[SupportedChainId.SCROLL].map((token) => token?.address),
  [SupportedChainId.SONIC]: whitelistTokens[SupportedChainId.SONIC].map((token) => token?.address),
}
