import { AutoColumn } from 'components/Column'
// import { SupportedChainId } from 'constants/chains'
import { SONIC_LIST } from 'constants/lists'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { SwapWidget } from 'lib'
import React, { useMemo } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { theme } from 'theme'
// import { tokenList } from './tokenlist'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const Swap = () => {
  const { library, chainId } = useActiveWeb3React()
  const darkMode = useIsDarkMode()
  const DEFAULT_TOKEN_LIST = SONIC_LIST
  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  return (
    <PageWrapper>
      <SwapWidget
        width={'600px'}
        tokenList={DEFAULT_TOKEN_LIST}
        provider={library}
        theme={themeObject}
        isDarkMode={darkMode}
      />
    </PageWrapper>
  )
}

export default Swap
