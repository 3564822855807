import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { ButtonPrimary } from 'components/Button'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { CHAIN_INFO } from 'constants/chainInfo'
import { DEXSupportedChainids, SupportedChainId } from 'constants/chains'
import { UUSD } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useChadBalance from 'hooks/useChadBalance'
import useTheme from 'hooks/useTheme'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'

import ChadLogo from '../../assets/images/logos/logo.png'
import MetamaskIcon from '../../assets/images/metamask.png'
import Menu from '../Menu'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  padding-bottom: 0.5rem;
  z-index: 20;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 128px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns:128px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr 64px;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`
const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right:0;
    justify-self:center;
  `};
  :hover {
    cursor: pointer;
  }
  text-decoration: none;
`

const UniIcon = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  color: ${({ theme }) => theme.titletext};
  font-weight: 400;
  position: relative;
  font-size: 0.85rem;
`
const NewLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`
const AppLogo = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 0.75rem;
`
const ButtonLogo = styled(NewLogo)<{ interactive?: boolean }>`
  margin-right: ${({ interactive }) => (interactive ? 8 : 0)}px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 8px;
  }
`

export default function Header() {
  const { account, chainId, library } = useActiveWeb3React()
  const chadBalance = useChadBalance(account ?? undefined)
  // const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { white, black } = useTheme()
  const location = useLocation()
  const scrollY = useScrollPosition()
  const {
    infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]
  const addToMetamask = async () => {
    const token = UUSD[chainId ?? 10]
    if (library && library?.provider?.isMetaMask && library.provider.request && token) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            //@ts-ignore // need this for incorrect ethers provider type
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: 'https://ik.imagekit.io/xcb8e11yh/cusd_v0.png?updatedAt=1708114497148',
            },
          },
        })
        .then((success) => {
          console.log(success)
        })
        .catch(() => console.log('haha'))
    } else {
    }
  }
  function formatNumber(num: any) {
    if (num % 1 === 0) {
      return num.toString()
    } else {
      return num.toFixed(2)
    }
  }
  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {/* <ClaimModal /> */}
      <Title href=".">
        <UniIcon>
          <AppLogo src={ChadLogo} alt="logo" />
          {/* <Logo fill={darkMode ? white : black} width="54px" height="54px" title="logo" /> */}
          <Trans>Chad Finance</Trans>
        </UniIcon>
      </Title>
      {chainId && DEXSupportedChainids.includes(chainId) ? <SwapPoolTabs active="swap" /> : <div></div>}
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={!!account}>
            {account && chadBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>
                  {formatNumber(chadBalance)} {'CUSD'}
                </Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElement>
          {account && library && library.provider?.isMetaMask && (
            <ButtonPrimary height={'40px'} onClick={addToMetamask}>
              <ButtonLogo interactive src={MetamaskIcon} />
              Add CUSD
            </ButtonPrimary>
          )}
        </HeaderElement>
        <HeaderElement>
          <Menu />
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
