import { Trans } from '@lingui/macro'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import PositionList from 'components/PositionList'
import Row, { RowBetween } from 'components/Row'
import VaultPositionList from 'components/VaultPositionList'
import WhitelistTokenModal from 'components/WhitelistTokensModal'
import { DEXSupportedChainids } from 'constants/chains'
import { whiteListTokenAddresses } from 'constants/whitelistToken'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useChadFinancePositions } from 'hooks/useChadFinancePositions'
import useConjurerVaultInfo from 'hooks/useConjurerVaultInfo'
import { useV3Positions } from 'hooks/useV3Positions'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Inbox, Plus } from 'react-feather'
import { useWalletModalToggle } from 'state/application/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { HideSmall, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

// import CTACards from './CTACards'
import { LoadingRows } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function Pool() {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const theme = useContext(ThemeContext)
  const [openWhitelistModal, setOpenWhitelistModal] = useState<boolean>(false)
  const { positions, loading: positionsLoading } = useV3Positions(account)

  const { positions: vaultPositions, loading: vaultLoading } = useChadFinancePositions(account)
  const { loading: conjurerLoading, result: conjurerVaultInfo } = useConjurerVaultInfo()
  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = useMemo(() => {
    return [...openPositions].filter(
      (position) =>
        whiteListTokenAddresses[chainId ?? 1]?.includes(position.token0) &&
        whiteListTokenAddresses[chainId ?? 1]?.includes(position.token1)
    )
  }, [openPositions, whiteListTokenAddresses])
  const showConnectAWallet = Boolean(!account)
  const dismisWhiteListModal = useCallback(() => {
    setOpenWhitelistModal(false)
  }, [openWhitelistModal])
  const handleOpenWhiteListModal = useCallback(() => {
    setOpenWhitelistModal(true)
  }, [openWhitelistModal])

  return (
    <>
      {chainId !== 1 ? (
        <PageWrapper>
          <Modal isOpen={openWhitelistModal} minHeight={30} onDismiss={dismisWhiteListModal}>
            <WhitelistTokenModal />
          </Modal>
          {/* <SwapPoolTabs active={'pool'} /> */}
          {/* <AutoColumn gap="lg" justify="center" style={{ marginBottom: '2rem' }}>
            <>
              {String(conjurerLoading) === 'false' && Boolean(conjurerVaultInfo.conjurerLimit) ? (
                <>
                  <AutoColumn>
                    <ThemedText.MediumHeader>
                      <Trans>Total Minted </Trans>
                    </ThemedText.MediumHeader>
                  </AutoColumn>

                  <ThemedText.LargeHeader>
                    <Trans>
                      {formatCurrencyAmount(conjurerVaultInfo.totalMinted, 3)}/
                      {formatCurrencyAmount(conjurerVaultInfo.conjurerLimit, 3)} CUSD
                    </Trans>
                  </ThemedText.LargeHeader>
                </>
              ) : (
                <> </>
              )}
            </>
          </AutoColumn> */}
          {/* <ContractWarningBanner /> */}
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                <ThemedText.Body fontSize={'20px'}>
                  <Trans>Vault Positions Overview</Trans>
                </ThemedText.Body>
              </TitleRow>

              <MainContentWrapper>
                {vaultLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : vaultPositions && vaultPositions.length > 0 ? (
                  <VaultPositionList positions={vaultPositions} />
                ) : (
                  <NoLiquidity>
                    <ThemedText.Body color={theme.text3} textAlign="center">
                      <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                      <div>
                        <Trans>Your active Vault positions will appear here.</Trans>
                      </div>
                    </ThemedText.Body>
                    {showConnectAWallet && (
                      <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                        <Trans>Connect a wallet</Trans>
                      </ButtonPrimary>
                    )}
                  </NoLiquidity>
                )}
              </MainContentWrapper>
            </AutoColumn>

            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow style={{ marginTop: '1rem', justifyContent: 'space-between' }} padding={'0'}>
                <Row style={{ width: 'max-content', display: 'flex', gap: '16px' }}>
                  <ThemedText.Body fontSize={'20px'}>
                    <Trans>Eligible LP Positions</Trans>
                  </ThemedText.Body>

                  <ButtonLight style={{ width: '170px', padding: '8px 16px' }} onClick={handleOpenWhiteListModal}>
                    {' '}
                    <Trans>Eligible Tokens &darr;</Trans>
                  </ButtonLight>
                </Row>

                <ButtonPrimary
                  style={{
                    height: '24px',
                  }}
                  width={'max-content'}
                  as={'a'}
                  href={chainId && DEXSupportedChainids.includes(chainId) ? '/#/add/' : 'https://app.uniswap.org/add'}
                >
                  <Plus size={14} strokeWidth={2} />
                  <Trans>New Position</Trans>
                </ButtonPrimary>
              </TitleRow>
              <MainContentWrapper>
                {positionsLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                  <PositionList positions={filteredPositions} redirectUrl="/pool/" />
                ) : (
                  <NoLiquidity>
                    <ThemedText.Body color={theme.text3} textAlign="center">
                      <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                      <div>
                        <Trans>Your eligible V3 liquidity positions will appear here.</Trans>
                      </div>
                    </ThemedText.Body>
                    {showConnectAWallet && (
                      <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                        <Trans>Connect a wallet</Trans>
                      </ButtonPrimary>
                    )}
                  </NoLiquidity>
                )}
              </MainContentWrapper>
              <HideSmall>{/* <CTACards /> */}</HideSmall>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <AutoColumn gap="lg" justify="center" style={{ marginBottom: '2rem' }}>
            <MainContentWrapper>
              <ThemedText.MediumHeader>
                We are currently live on Scroll chain, and gearing up for our upcoming mainnet launch. Stay tuned ...
              </ThemedText.MediumHeader>
            </MainContentWrapper>
          </AutoColumn>
        </PageWrapper>
      )}
    </>
  )
}
